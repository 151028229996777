// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bildgroessenberechnung-index-js": () => import("./../../../src/pages/bildgroessenberechnung/index.js" /* webpackChunkName: "component---src-pages-bildgroessenberechnung-index-js" */),
  "component---src-pages-das-unternehmen-index-js": () => import("./../../../src/pages/das-unternehmen/index.js" /* webpackChunkName: "component---src-pages-das-unternehmen-index-js" */),
  "component---src-pages-farbmuster-index-js": () => import("./../../../src/pages/farbmuster/index.js" /* webpackChunkName: "component---src-pages-farbmuster-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-stanzerei-index-js": () => import("./../../../src/pages/stanzerei/index.js" /* webpackChunkName: "component---src-pages-stanzerei-index-js" */),
  "component---src-pages-werbetechnik-index-js": () => import("./../../../src/pages/werbetechnik/index.js" /* webpackChunkName: "component---src-pages-werbetechnik-index-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

